'use client'

import { ModalComponent } from '@/context/ModalContext'
import Modal from '@/views/modal/Modal'
import { XImage } from '@/views/common/XImage'
import { Images } from '@/utils/images'
import { useTranslations } from 'next-intl'
import { gameService, RankingType } from '@/utils/http/services/game'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import { useRequest } from 'ahooks'
import R from '@/utils/http/request'
import { LoadingFrame } from '@/views/common/Loading'
import { useUserContext } from '@/context/UserContext'

export const RankingModal: ModalComponent<{}, { game: string }> = (props) => {
  const [type, setType] = useState<RankingType>(RankingType.SINGLE_GAME)
  const { isLogin } = useUserContext()

  useEffect(() => {
    const handler = (e: Event) => {
      e.preventDefault()
      e.stopPropagation()
    }
    window.document.body.addEventListener('scroll', handler, { passive: false })
    document.documentElement.scrollTop = 200
    return () => {
      window.document.body.removeEventListener('scroll', handler)
    }
  }, [])

  return (
    <Modal
      closeOnBackdrop
      noTransition
      className={clsx(
        'md:w-[414px] fixed max-md:left-6 md:top-[115px] top-0 right-0 bottom-0  lg:rounded-l-[32px] !rounded-r-none overflow-hidden bg-ranking-flappy pt-15 md:pl-6 pl-2',
      )}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <div
        className={
          'md:w-10 md:h-10 w-7.5 h-6 rounded-r-full bg-yellowFEDE08 flex justify-center items-center absolute left-0 top-4.5 cursor-pointer'
        }
        onClick={props.onClose}
      >
        <XImage src={Images.GAME.LEADER_RIGHT_SVG} className={'md:h-4 h-2.5'} />
      </div>
      <BannerText game={props.game} />

      <RankTypeSelect value={type} onChange={setType} />
      <RankingList type={type} game={props.game} />
      {isLogin && <CurrentRanking type={type} game={props.game} />}
    </Modal>
  )
}

function RankingList(props: { type: RankingType; game: string }) {
  const { loading, data } = useRequest(
    R(gameService.rankingList, { ranking_type: props.type, game_name: props.game }),
    {
      refreshDeps: [props.type, props.game],
    },
  )
  const list = data || []
  return (
    <ul
      className={clsx(
        'absolute top-50 md:left-6 md:right-6 left-3 right-3 bottom-0 border-t border-[rgba(255,255,255,0.2)] pt-6 text-sm text-whiteE3',
        'overflow-y-scroll overflow-x-hidden',
      )}
    >
      {loading ? (
        <LoadingFrame size={60} />
      ) : (
        list.map((item, index) => (
          <li
            key={item.user_name + index}
            className={'h-7.5 w-full flex justify-between items-center relative first:mt-0 mt-4'}
          >
            <span className={'text-gray808C9C w-[12.5%]'}>{index + 1}</span>
            <span className={'w-[43%] flex justify-start items-center gap-x-3'}>
              <XImage src={item.user_avatar} className={'w-7.5 h-7.5'} />
              <span>{item.user_name}</span>
            </span>
            <span>{item.ranking_value}</span>
            <span className={'flex flex-end items-center'}>
              + {item.award_points} <XImage className={'h-3.5 ml-1.5'} src={Images.GAME.RANKING_MEDAL_PNG} />
            </span>
          </li>
        ))
      )}
    </ul>
  )
}

function CurrentRanking(props: { type: RankingType; game: string }) {
  const { loading, data } = useRequest(
    R(gameService.getUserRank, {
      ranking_type: props.type,
      game_name: props.game,
    }),
    {
      refreshDeps: [props.type, props.game],
    },
  )
  const t = useTranslations('play')
  return (
    <div
      className={
        'md:w-[calc(100%-48px)] w-[calc(100%-24px)] h-[66px] rounded-[20px] border border-[rgba(255,255,255,0.2)] bg-black16142E absolute left-6 bottom-3 pt-3 pl-4'
      }
    >
      <h3 className={'text-whiteE3 text-sm font-normal'}>{t('current_rank', { rank: data?.ranking || 0 })}</h3>
      <p className={'mt-2 text-gray808C9C text-xs'}>{t('top_text')}</p>
      <span className={'flex flex-end items-center text-whiteE3 text-sm absolute top-6.5 right-4'}>
        + {data?.award_points || 0} <XImage className={'h-3.5 ml-1.5'} src={Images.GAME.RANKING_MEDAL_PNG} />
      </span>
    </div>
  )
}

function RankTypeSelect(props: { value: RankingType; onChange: (value: RankingType) => void }) {
  const t = useTranslations('play')
  const ref = useRef<HTMLDivElement>(null)
  return (
    <div className={'w-full h-6 mt-4 relative'}>
      <div
        ref={ref}
        className={'w-fit flex justify-start items-center  flex-nowrap gap-x-2 overflow-auto no-scrollbar select-none'}
      >
        {[RankingType.SINGLE_GAME, RankingType.GAME_TIMES, RankingType.NFT_FRAGMENT, RankingType.NFT].map((type) => (
          <span
            key={type}
            onClick={(e) => {
              props.onChange(type)
              if (ref.current) {
                const rect = ref.current.getBoundingClientRect()
                const currentRect = e.currentTarget.getBoundingClientRect()
                ref.current.scrollTo({
                  left: currentRect.x - rect.x,
                  behavior: 'smooth',
                })
              }
            }}
            className={clsx(
              'h-6  cursor-pointer inline-block rounded-full px-3.5 leading-6  text-sm break-keep flex-shrink-0',
              props.value === type
                ? 'bg-ranking text-black070921'
                : 'bg-[rgba(255,255,255,0.2)] text-[rgba(255,255,255,0.6)]',
            )}
          >
            {t(type)}
          </span>
        ))}
      </div>
    </div>
  )
}

function BannerText(props: { game: string }) {
  const t = useTranslations('play')
  if (props.game === 'FlappyCrypto')
    return (
      <p className={'text-[28px] text-white'}>
        Flappy Crypto
        <XImage src={Images.GAME.ICON_FLAPPY_SVG} className={'w-11 rotate-[18deg] ml-5 inline'} />
        <br />
        {t('weekly_leaderboard')}
      </p>
    )
  if (props.game === 'MEME-Merge2048')
    return (
      <p className={'text-[28px] text-white'}>
        MEME-Merge 2048
        <XImage src={Images.GAME.ICON_2048_PNG} className={'w-11 rotate-[18deg] ml-5 inline'} />
        <br />
        {t('weekly_leaderboard')}
      </p>
    )
  if (props.game === 'MEME-MineSweeper')
    return (
      <p className={'text-[28px] text-white'}>
        MEME-Mine Sweeper
        <XImage src={Images.GAME.ICON_MINE_PNG} className={'w-11 rotate-[18deg] ml-5 inline'} />
        <br />
        {t('weekly_leaderboard')}
      </p>
    )
  return (
    <p className={'text-[28px] text-white'}>
      Doge Jump Challenge
      <XImage src={Images.GAME.ICON_DOG_PNG} className={'w-11 rotate-[18deg] ml-5 inline'} />
      <br />
      {t('weekly_leaderboard')}
    </p>
  )
}
