import CopyRight from './CopyRight'
import Menu from './Menu'
import Sociaty from './Sociaty'
import { usePathname, useSearchParams } from 'next/navigation'

const Index = () => {
  const pathName = usePathname()
  return (
    <div className={`w-full px-2 ${pathName.includes('/airdrop') ? 'block' : 'hidden'}`}>
      <Sociaty />
      <CopyRight />
      {/* <Menu /> */}
    </div>
  )
}
export default Index
