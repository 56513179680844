'use client'

import { useEffect } from 'react'
import { clearAllToken } from '@/utils/http/token'

export function TokenClear() {
  useEffect(() => {
    clearAllToken()
    location.reload()
  }, [])
  return null
}
