'use client'
import { XImage } from '../common/XImage'
import { useLocale, useTranslations } from 'next-intl'
import { useRouter, usePathname } from '@/navigation'
import { useEffect, useState } from 'react'

const Menu = () => {
  const router = useRouter()
  const locale = useLocale()
  const pathname = usePathname()
  const t = useTranslations('asider')
  const [showEventMenu, setShowEventMenu] = useState(false)

  useEffect(() => {
    if (pathname === '/referrals' || pathname === '/airdrop') {
      // setShowEventMenu(true)
    } else {
      setShowEventMenu(false)
    }
  }, [pathname])
  return (
    <div className="fixed bottom-[0] left-0 z-10 py-[8px] px-[23px] bg-[#16142E] roundex-[30px] w-full justify-between flex items-center gap-x-[22px]">
      <div
        className="flex flex-col items-center cursor-pointer"
        onClick={() => {
          // @ts-ignore
          router.replace({ pathname: '/points' }, { locale: locale })
        }}
      >
        <XImage
          src={
            pathname === '/points' ? '/images/mobilemenu/icon_points_click.png' : '/images/mobilemenu/icon_points.png'
          }
          width={28}
        />
        <div className={`${pathname === '/points' ? 'text-[#A0EED6]' : 'text-[#5E6C7F]'} text-[10px] mt-[2px]`}>
          {t('Integral')}
        </div>
      </div>
      <div
        className="flex flex-col items-center cursor-pointer"
        onClick={() => {
          // @ts-ignore
          router.replace({ pathname: '/referrals' }, { locale: locale })
        }}
      >
        <XImage
          src={
            pathname === '/referrals'
              ? '/images/mobilemenu/icon_invite_click.png'
              : '/images/mobilemenu/icon_invite.png'
          }
          width={28}
        />
        <div className={`${pathname === '/referrals' ? 'text-[#A0EED6]' : 'text-[#5E6C7F]'} text-[10px] mt-[2px]`}>
          {t('Invite')}
        </div>
      </div>

      <div
        className={`flex flex-col items-center  transition-all mt-[-33px]  cursor-pointer`}
        onClick={() => {
          // @ts-ignore
          router.replace({ pathname: '/' }, { locale: locale })
        }}
      >
        <XImage
          src={pathname === '/' ? '/images/mobilemenu/icon_game_click.png' : '/images/mobilemenu/icon_game.png'}
          width={62}
        />
        <div className={`${pathname === '/' ? 'text-[#A0EED6]' : 'text-[#5E6C7F]'} text-[10px] mt-[0]`}>
          {t('Games')}
        </div>
      </div>
      <div
        className="flex flex-col items-center cursor-pointer"
        onClick={() => {
          // @ts-ignore
          router.replace({ pathname: '/personal-center' }, { locale: locale })
        }}
      >
        <XImage
          src={
            pathname === '/personal-center' ? '/images/mobilemenu/icon_my_click.png' : '/images/mobilemenu/icon_my.png'
          }
          width={28}
        />
        <div
          className={`${pathname === '/personal-center' ? 'text-[#A0EED6]' : 'text-[#5E6C7F]'} text-[10px] mt-[2px]`}
        >
          {t('My')}
        </div>
      </div>
      <div
        className="flex flex-col items-center cursor-pointer"
        onClick={() => {
          // @ts-ignore
          router.replace({ pathname: '/airdrop' }, { locale: locale })
        }}
      >
        <XImage
          src={
            pathname === '/airdrop'
              ? '/images/mobilemenu/icon_airdrop_click.png'
              : '/images/mobilemenu/icon_airdrop.png'
          }
          width={28}
        />
        <div className={`${pathname === '/airdrop' ? 'text-[#A0EED6]' : 'text-[#5E6C7F]'} text-[10px] mt-[2px]`}>
          {t('Airdrop')}
        </div>
      </div>
    </div>
  )
}
export default Menu
