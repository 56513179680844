import { useTranslations } from 'next-intl'

const useAsiderConstants = () => {
  const t = useTranslations('asider')
  const menuList = [
    { icon: '/images/asider/home.svg', text: t('Home'), path: '/', activeIcon: '/images/asider/home_active.svg' },
    {
      icon: '/images/asider/invite.svg',
      text: t('Invite'),
      path: '/referrals',
      activeIcon: '/images/asider/invite_active.svg',
    },
    {
      icon: '/images/asider/integral.svg',
      text: t('Integral'),
      path: '/points',
      activeIcon: '/images/asider/integral_active.svg',
    },
    {
      icon: '/images/asider/personalcenter.svg',
      text: t('Personal Center'),
      path: '/personal-center',
      activeIcon: '/images/asider/personalcenter_active.svg',
    },
    {
      icon: '/images/asider/airdrop.svg',
      text: t('Airdrop'),
      path: '/airdrop',
      activeIcon: '/images/asider/airdrop_active.svg',
    },
  ]

  const gameList = [
    { icon: '/images/home/banner/fla.png', path: '/play/FlappyCrypto' },
    { icon: '/images/home/banner/gold-miner.png', path: '/play/MEME-Miner' },
    { icon: '/images/home/banner/match-pop.png', path: '/play/MatchPop' },
    { icon: '/images/home/banner/merge-melon.png', path: '/play/MEME-Melon' },
    { icon: '/images/home/banner/2048.png', path: '/play/MEME-Merge2048' },
    { icon: '/images/home/banner/SWEE.png', path: '/play/MEME-MineSweeper' },
    { icon: '/images/home/banner/doge-jump.png', path: '/play/DogeJumpChallenge' },
  ]

  const languageList = [
    { label: 'English', value: 'en' },
    { label: '中文', value: 'zh' },
    { label: 'Français', value: 'fr' },
    { label: '한국인', value: 'ko' },
    { label: 'Tiếng Việt', value: 'vi' },
    { label: '日本語', value: 'ja' },
    { label: 'Русский', value: 'ru' },
  ]
  return { menuList, gameList, languageList }
}
export default useAsiderConstants
