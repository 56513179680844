import { useModal } from '@/context/ModalContext'
import { useUserContext } from '@/context/UserContext'
import SelectChainModal from './SelectChainModal'
import { useTranslations } from 'next-intl'
import { ChainType } from '@/context/ChainTypeContext'

const ConnectWallet = () => {
  const t = useTranslations('header')
  const { isLogin, signIn, signOut, userInfo } = useUserContext()
  const { openModal } = useModal()

  const checkWalletEnv = () => {
    const isSupportPhantom = !!window.phantom
    const isSupportSolflare = !!window.solflareWalletStandardInitialized
    if (isSupportSolflare || isSupportPhantom) {
      // 存在sol的环境
      signIn(ChainType.Solana)
      return true
    } else {
      // 任何环境不存在
      return false
    }
  }
  return (
    <span
      className="bg-[#1E1B3B] lg:bg-[#A0EED6] py-[6px] lg:py-[10px] px-[21px] lg:px-[42px] rounded-[21px] text-[#A2B1C4] lg:text-[#1A1D1F] text-[12px] lg:text-[16px] cursor-pointer"
      onClick={async () => {
        // if (!isMobile) {
        //   signIn(ChainType.Solana)
        // } else {
        //   const res = checkWalletEnv()
        //   if (!res) {
        //     const rst = await openModal(SelectChainModal, {})
        //   }
        // }
        window.enableLoginConfirm = false
        signIn(ChainType.Ton)
      }}
    >
      {t('Connect Wallet')}
    </span>
  )
}
export default ConnectWallet
