'use client'
import { AppProgressBar as ProgressBar } from 'next-nprogress-bar'
import { Suspense } from 'react'

export default function NProgressBar() {
  return (
    <Suspense>
      <ProgressBar height={'4px'} color={'#A0EED6'} options={{ showSpinner: false }} shallowRouting />
    </Suspense>
  )
}
