import { SociatyList } from '@/utils/constants'
import { XImage } from '../common/XImage'

const Sociaty = () => {
  return (
    <div className="flex justify-center gap-x-[24px]">
      {SociatyList.map((item, index) => {
        return (
          <XImage
            key={index}
            src={item.img}
            alt=""
            width={index === SociatyList.length - 1 ? 13 : 18}
            className="cursor-pointer"
            onClick={() => {
              window.open(item.link, '_blank')
            }}
          />
        )
      })}
    </div>
  )
}
export default Sociaty
