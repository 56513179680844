'use client'
import Header from '@/views/components/Header'
import { usePathname, useSearchParams } from 'next/navigation'
import Footer from '@/views/footer'
import Menu from '../footer/Menu'

const Main = ({ children }: { children: React.ReactNode }) => {
  const pathName = usePathname()
  const searchParams = useSearchParams()
  const fullscreen = searchParams.get('fullscreen')
  return (
    <main
      className={`flex-1 min-w-0 bg-gradient-asider min-h-screen xs:!pl-0 xs:!bg-none xs:!bg-[#080921] bg-[#080921]`}
    >
      {/* <div className="flex-1 relative">
        <div className="absolute top-[24px] px-2 lg:top-[50px] left-0 lg:right-[90px] w-full z-10">
          <Header />
        </div>
      </div>
      <main className="pb-[40px] lg:pb-0  relative z-10">{children}</main>
      <div className="block lg:hidden">
        <Footer />
      </div>
      <Menu /> */}
      <div className="flex-1 relative">
        <div className="fixed lg:absolute top-[0px] h-[60px] bg-[#080920] flex items-center px-2 lg:top-[30px] left-0 lg:right-[90px] w-full z-10">
          <Header />
        </div>
        <main className={`pb-[40px] lg:pb-0 relative ${!!fullscreen ? 'z-20' : 'z-[9]'}`}>{children}</main>
        <div className={`${fullscreen ? 'hidden' : 'block'} lg:hidden pb-[50px]`}>
          <Footer />
          <Menu />
        </div>
      </div>
    </main>
  )
}

export default Main
