'use client'

import { useEffect } from 'react'
import { storage } from '@/utils/storage'
import { useRouter } from 'next-nprogress-bar'
import { isTMA } from '@telegram-apps/sdk'
import { useRequest } from 'ahooks'
import { useSelectedLayoutSegment } from 'next/navigation'

export function SceneRevertGuard() {
  const router = useRouter()
  const { data } = useRequest(isTMA)
  const segment = useSelectedLayoutSegment()

  useEffect(() => {
    if (!data) return
    // const launchParams = retrieveLaunchParams()
    // console.log('launchParams', launchParams, location.href, location.hash, location.search)
    const sceneData = storage.getItem('sceneData')
    if (sceneData) {
      const url = sceneData.url
      if (url !== location.pathname) {
        router.push(url)
      }
    }
  }, [data, segment])
  return null
}
