'use client'

import { XImage } from '@/views/common/XImage'
import { FC, ReactNode, useEffect, useState } from 'react'
import useAsiderConstants from './useAsiderConstants'
// import { Select, SelectItem } from '@nextui-org/select'
import { useRouter, usePathname } from '@/navigation'
import { useParams, useSearchParams } from 'next/navigation'
import { useLocale, useTranslations } from 'next-intl'
import { useSelectedLayoutSegment } from 'next/navigation'
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons'
import Select from 'react-select'
import { SociatyList } from '@/utils/constants'
import clsx from 'clsx'

const AsiderItem: FC<{ label: { icon: string; text: ReactNode }; content: ReactNode; isExpand: boolean }> = ({
  label,
  content,
  isExpand,
}) => {
  return (
    <div>
      {isExpand && (
        <div className="flex gap-x-2 items-center">
          <div className="">
            <XImage src={label.icon} alt="" />
          </div>
          <div className="text-[#E3E3E3]">{label.text}</div>
        </div>
      )}

      <div className="mt-[40px] px-4">{content}</div>
    </div>
  )
}

const MenuItem: FC<{ icon: string; text: string; onClick?: () => void; isSelected: boolean; isExpand: boolean }> = ({
  icon,
  text,
  isSelected,
  onClick,
  isExpand,
}) => {
  return (
    <div
      className={`flex items-center gap-x-3 rounded-[14px]  cursor-pointer ${
        isSelected ? (isExpand ? 'bg-[#FEDE08]' : '') : ''
      } ${isExpand ? 'py-[12px] px-[16px]' : ''}`}
      onClick={() => {
        onClick && onClick()
      }}
    >
      <span>
        <XImage src={icon} className={isExpand ? '' : 'w-[30px]'} />
      </span>
      {isExpand && <span className={`${isSelected ? 'text-[#16142E]' : 'text-[#5E6C7F]'}`}>{text}</span>}
    </div>
  )
}

const Asider = () => {
  const t = useTranslations('asider')
  // const selectedLayoutSegment = useSelectedLayoutSegment()
  const { gameList, menuList, languageList } = useAsiderConstants()
  const searchParams = useSearchParams()
  const router = useRouter()
  const pathname = usePathname()
  const params = useParams()
  const locale = useLocale()
  const [isExpand, setIsExpand] = useState(true)
  const gamePathList = gameList.map((item) => item.path)
  useEffect(() => {
    if (!gamePathList.includes(pathname)) {
      setIsExpand(true)
    } else {
      setIsExpand(false)
    }
  }, [pathname])
  return (
    <div className="bg-[#16142E] h-screen sticky left-0 top-0">
      <div
        className={`bg-[#16142E] w-[320px]  py-[31px] transition-all   overflow-x-hidden overflow-y-scroll no-scrollbar ${
          isExpand ? 'w-[320px] px-[37px]' : 'w-[90px] px-[6px]'
        }`}
        style={{ height: isExpand ? 'calc(100vh - 190px)' : '100vh' }}
      >
        <div className="flex justify-center relative">
          <XImage
            src={`${isExpand ? '/images/common/site.svg' : '/images/common/site-s.svg'}`}
            alt=""
            className={`${isExpand ? 'w-[157px]' : 'w-[45px]'}`}
          />
          {gamePathList.includes(pathname) && (
            <div
              className={`absolute text-[#5E6C7F] cursor-pointer ${isExpand ? 'right-0' : 'right-0 top-[-25px]'}`}
              onClick={() => {
                setIsExpand(!isExpand)
              }}
            >
              {isExpand ? <LeftCircleOutlined /> : <RightCircleOutlined />}
            </div>
          )}
        </div>
        <div className="mt-[50px]">
          <AsiderItem
            isExpand={isExpand}
            label={{ icon: '/images/asider/game.svg', text: t('GAME') }}
            content={
              <div
                className={`${
                  isExpand ? 'grid grid-cols-2 gap-x-5 gap-y-4' : 'flex flex-col gap-y-[20px] items-center'
                }`}
              >
                {gameList.map((item, index) => {
                  return (
                    <XImage
                      className={clsx(
                        `cursor-pointer ${isExpand ? 'rounded-2xl' : 'w-[45px]'}`,
                        isExpand ? '' : 'rounded-full w-[46px] h-[46px] object-cover object-center',
                      )}
                      key={index}
                      src={item.icon}
                      onClick={() => {
                        // setIsExpand(false)
                        //@ts-ignore
                        router.replace({ pathname: item.path })
                        // @ts-ignore
                        // location.replace({ pathname: item.path }, { locale: locale })
                      }}
                    />
                  )
                })}
              </div>
            }
          />
        </div>
        <div className="bg-[#252439] h-[1px] mt-[38px]" />
        <div className="mt-[37px]">
          <AsiderItem
            isExpand={isExpand}
            label={{ icon: '/images/asider/activities.svg', text: t('ACTIVITIES') }}
            content={
              <div className={`flex flex-col mt-[-12px] ${isExpand ? '' : 'gap-y-[35px] items-center'}`}>
                {menuList.map((menu, index) => {
                  return (
                    <MenuItem
                      isExpand={isExpand}
                      key={index}
                      icon={menu.path === pathname ? menu.activeIcon : menu.icon}
                      text={menu.text}
                      isSelected={menu.path === pathname}
                      onClick={() => {
                        // @ts-ignore
                        router.replace({ pathname: menu.path }, { locale: locale })
                      }}
                    />
                  )
                })}
              </div>
            }
          />
        </div>
      </div>
      {isExpand && (
        <>
          <div className="z-[10] px-[37px] absolute bottom-[85px] w-[320px]">
            <div className="mt-[21px]">
              <AsiderItem
                isExpand={isExpand}
                label={{ icon: '/images/asider/language.svg', text: t('LANGUAGE') }}
                content={
                  <div className="flex flex-col mt-[-12px]">
                    <Select
                      // menuPortalTarget={document.body}
                      menuPlacement="top"
                      defaultValue={languageList.find((language) => language.value === locale)}
                      classNamePrefix="select"
                      options={languageList.map((language) => ({ label: language.label, value: language.value }))}
                      onChange={(e) => {
                        console.log('e', e)
                        // @ts-ignore
                        // router.replace({ pathname, params, query: { a: 1 } }, { locale: e.value })
                        router.replace({ pathname, params }, { locale: e.value })
                      }}
                    />
                  </div>
                }
              />
            </div>
          </div>
          <div className="flex gap-x-[19px] mt-[120px] justify-center z-[10] absolute bottom-[25px]  w-[320px]">
            {SociatyList.map((item, index) => {
              return (
                <XImage
                  key={index}
                  src={item.img}
                  alt=""
                  width={25}
                  className="cursor-pointer"
                  onClick={() => {
                    window.open(item.link, '_blank')
                  }}
                />
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}
export default Asider
