'use client'

import { useEffect } from 'react'
import { storage } from '@/utils/storage'
import { useTonConnectUI } from '@tonconnect/ui-react'

export function RemoveSceneData() {
  const [ton] = useTonConnectUI()
  useEffect(() => {
    if (ton.connected) {
      storage.remove('sceneData')
    }
  }, [ton?.connected])
  return null
}
