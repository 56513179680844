'use client'
import { useRequest } from 'ahooks'
import { isTMA } from '@telegram-apps/sdk'
import { useEffect } from 'react'
import { setCookie } from '@/utils/cookie'

export default function TMAGuard() {
  const { data } = useRequest(isTMA)
  useEffect(() => {
    if (data) {
      setCookie('isTMA', 'true')
    }
  }, [data])
  return null
}
