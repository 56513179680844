'use client'
import { useUserContext } from '@/context/UserContext'
import { useSolWallet } from '@/context/SolWalletContext'
import { XImage } from '@/views/common/XImage'
import Dropdown from '@/views/common/Dropdown'
import useMobile from '@/hooks/useMobile'
import useAsiderConstants from '../Asider/useAsiderConstants'
import { useRouter, usePathname } from '@/navigation'
import { useParams, useSelectedLayoutSegment } from 'next/navigation'
import { useLocale, useTranslations } from 'next-intl'
import { useModal } from '@/context/ModalContext'
// import ConnectWallet from '../ConnectWallet/SelectSolWalletApp'
// import SelectChainModal from '../ConnectWallet/SelectChainModal'
import ConnectWallet from '../ConnectWallet'
import { Suspense, useEffect, useState } from 'react'
import { useRequest } from 'ahooks'
import { isTMA, retrieveLaunchParams } from '@telegram-apps/sdk'
import { isBrowser } from '@/utils/env'
import { useRouter as useRouter2 } from 'next/navigation'
import Drawer from '@/views/common/Drawer'
import { RankingEntry } from '@/views/play/RankingEntry'
import { tgBot, tgChannel } from '@/utils/constants'
import { EarnChanceModal } from '@/views/play/EarnChanceModal'
import { useTonConnect } from '@/hooks/ton/useTonConnect'
import { storage } from '@/utils/storage'
import { personalCenterService } from '@/utils/http/services/personal-center'
import InviteModel from '@/views/InviteModal'

const pathName2text = {
  '/': 'Game',
  '/points': 'Points',
  '/referrals': 'Referrals',
  '/personal-center': 'My',
  '/airdrop': 'Airdrop',
}

const Header = () => {
  const pathname = usePathname()
  const router = useRouter()
  const router2 = useRouter2()
  const params = useParams()
  const locale = useLocale()
  const { openModal } = useModal()
  const { languageList } = useAsiderConstants()
  const isMobile = useMobile()
  const t = useTranslations('header')
  const tMy = useTranslations('personal-center')
  const tAsider = useTranslations('asider')
  const { isLogin, signIn, signOut, userInfo, refreshUserInfo } = useUserContext()
  const wallet = useSolWallet()
  const address = wallet.publicKey?.toBase58()
  const { noneBounceableAddress } = useTonConnect()
  // useEffect(() => {
  //   if (isLogin && address && userInfo?.user_address !== address) {
  //     signOut()
  //   }
  // }, [address, userInfo?.user_address, isLogin])
  const { data } = useRequest(isTMA, {
    onSuccess: () => {},
  })

  const [isShowDrawer, setIsShowDrawer] = useState(false)
  useEffect(() => {
    if (data && locale) {
      const launchParams = retrieveLaunchParams()
      if (isBrowser && launchParams.startParam) {
        if (launchParams.startParam.startsWith('route-')) {
          const route = launchParams.startParam.split('route-')[1]
          // @ts-ignore
          router.replace({ pathname: '/' + route }, { locale: locale })
        } else {
          const inviteCode = launchParams.startParam.split('--')[0]
          const inviteRootCode = launchParams.startParam.split('--')[1]
          storage.setItem('inviteCode', inviteCode)
          if (inviteRootCode) {
            storage.setItem('inviteRootCode', inviteRootCode)
          }
        }
      }
    }
  }, [data])

  const segment = useSelectedLayoutSegment()
  return (
    <div className={`flex w-full justify-between lg:pr-[30px] lg:min-w-[1032px]`}>
      <Suspense fallback={<span></span>}>
        <Drawer
          onClose={() => {
            setIsShowDrawer(false)
          }}
          open={isShowDrawer}
        >
          <div>
            <div className="text-[22px] text-[#fff] text-center">{tAsider('LANGUAGE')}</div>
            <div className="mt-[32px] flex flex-col gap-y-[8px]">
              {languageList.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`relative flex justify-between items-center bg-[#16142E] rounded-[20px] p-[14px]`}
                    onClick={() => {
                      setIsShowDrawer(false)
                      // @ts-ignore
                      router.replace({ pathname, params }, { locale: item.value })
                    }}
                  >
                    {locale === item.value && (
                      <div
                        className="bg-[url('/images/header/border.svg')] absolute left-0 top-0 w-full h-full"
                        style={{ backgroundSize: '100% 100%' }}
                      />
                    )}
                    <div className="flex items-center gap-x-[16px]   ">
                      <span className="text-[16px] text-[#808C9C]">{item.label}</span>
                    </div>
                    {locale === item.value && (
                      <div>
                        <XImage src="/images/points/checked.png" width={20} />
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </Drawer>
      </Suspense>

      {/* <XImage src="/images/common/site.svg" width={73} className="block lg:hidden" /> */}
      <div
        className="hidden lg:flex items-center gap-x-[20px] pl-[80px]"
        onClick={() => {
          window.open(tgBot, '_blank')
        }}
      >
        <div className="bg-[#A0EED6] cursor-pointer rounded-[21px] p-[8px] pr-[16px] text-[#070921] text-[16px] flex items-center gap-x-[8px]">
          <XImage src="/images/header/tonicon.png" width={24} />
          @YOMEBOT
        </div>
        <div
          className="bg-[#FEDE08] cursor-pointer rounded-[21px] p-[8px] pr-[16px] text-[#070921] text-[16px] flex items-center gap-x-[8px]"
          onClick={() => {
            window.open(tgChannel, '_blank')
          }}
        >
          <XImage src="/images/header/tonicon.png" width={24} />
          @YOME_News
        </div>
      </div>
      {
        // @ts-ignore
        pathname !== '/personal-center' && !(pathname || '')?.includes('/play') ? (
          <div className="text-[28px] text-[#fff] relative px-[16px] pr-[17px] block md:hidden">
            <span>{pathName2text[pathname]}</span>
            <XImage src="/images/header/pathicon.png" className="absolute left-0 bottom-[3px]" width={16} />
            <XImage src="/images/header/pathstar.png" className="absolute right-0 top-[0px]" width={17} />
          </div>
        ) : (
          <div />
        )
      }
      <div className="flex justify-end relative z-10 items-center gap-x-[10px] lg:gap-x-[25px] cursor-pointer">
        {pathname === '/personal-center' && (
          <div className="flex items-center md:hidden">
            <XImage
              src="/images/header/language.svg"
              width={24}
              onClick={() => {
                setIsShowDrawer(!isShowDrawer)
              }}
            />
          </div>
        )}
        <XImage
          src="https://yome-game.s3.us-east-2.amazonaws.com/icons/coin_icon/TON.svg"
          className="w-[24px] block md:hidden"
        />

        {isLogin && noneBounceableAddress ? (
          <div className="flex gap-x-[10px] lg:gap-x-[25px] items-center">
            <div className="md:flex flex-col items-center hidden">
              <div className="text-[#A2EDD0] text-[12px] lg:text-[16px] flex items-center gap-x-[5px]">
                <XImage src="/images/header/points.svg" />
                {userInfo?.points ? userInfo?.points.toFixed(2) : 0}
              </div>
              <div className="lg:mt-[1px] text-[#808C9C]  text-[10px] lg:text-[14px]">{t('My Points')}</div>
            </div>
            <span
              className="rounded-[20px] bg-[#A0EED6] md:flex hidden items-center gap-x-[8px] py-[12px] pl-[13px] pr-[5px] cursor-pointer"
              onClick={async () => {
                const rst = await openModal(EarnChanceModal, {})
                if (rst) {
                  refreshUserInfo()
                }
              }}
            >
              <span className="text-[#070921] text-[12px]">
                {tMy('Lucky Chances')}: {userInfo?.additional_times || 0}
              </span>
              <XImage src="/images/my/add_luck_chance.png" width={16} />
            </span>
            <Dropdown
              menu={[
                {
                  label: (
                    <div
                      className="bg-[#2B2845] flex gap-x-[14px] rounded-[10px]  items-center cursor-pointer transition-all px-[11px] py-[4px] lg:py-[8px] hover:bg-[#2B2845]"
                      onClick={signOut}
                    >
                      <XImage src="/images/icon/logout.svg" width={20} />
                      <span className="text-[12px] lg:text-[14px] text-[#808C9C]">{t('LOGOUT')}</span>
                    </div>
                  ),
                },
              ]}
            >
              <span className="bg-[#1E1B3B] lg:bg-[#A0EED6] py-[10px] lg:py-[13px] px-[21px] lg:px-[42px] rounded-[21px] text-[#A2B1C4] lg:text-[#1A1D1F] text-[12px] lg:text-[16px] cursor-pointer">
                {noneBounceableAddress.slice(0, isMobile ? 5 : 8)}...$
                {noneBounceableAddress.slice(isMobile ? -4 : -8)}
              </span>
            </Dropdown>
          </div>
        ) : (
          <ConnectWallet />
        )}
        {segment === 'play' && <RankingEntry />}
        <XImage
          src="https://yome-game.s3.us-east-2.amazonaws.com/icons/coin_icon/TON.svg"
          className="w-[38px] hidden md:block"
        />
      </div>
    </div>
  )
}
export default Header
