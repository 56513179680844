'use client'

import { ITonConnect, TonConnect, TonConnectUIProvider } from '@tonconnect/ui-react'
import { isBrowser, isServer, isTest } from '@/utils/env'

let connector: ITonConnect | undefined
export default function TonConnectUIProviderWrapper(props: { children: React.ReactNode }) {
  if (isBrowser) {
    connector = new TonConnect({
      manifestUrl: 'https://yome.finance/ton/mainifest.json',
      walletsListSource: '/wallets-json',
    })
  }
  return (
    <TonConnectUIProvider
      restoreConnection
      actionsConfiguration={{
        modals: ['before'],
        notifications: [],
        returnStrategy: 'back',
        twaReturnUrl: isPC()
          ? isTest
            ? 'https://t.me/yome_test_bot/yome'
            : 'https://t.me/YomeGame_Bot/YomeGame'
          : undefined,
      }}
      connector={connector}
    >
      {props.children}
    </TonConnectUIProvider>
  )
}

function isPC() {
  if (isServer) return false
  return !!window.navigator.userAgent.match(/Windows/i)
}
