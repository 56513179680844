'use client'
import { storage } from '@/utils/storage'
import { retrieveLaunchParams } from '@telegram-apps/sdk'
import { FC, PropsWithChildren, useEffect } from 'react'

export const FromInviteCode: FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    try {
      const launchParams = retrieveLaunchParams()
      if (launchParams.startParam) {
        if (launchParams.startParam.startsWith('route-')) {
          const route = launchParams.startParam.split('route-')[1]
          // @ts-ignore
          router.replace({ pathname: '/' + route }, { locale: locale })
        } else {
          const inviteCode = launchParams.startParam.split('--')[0]
          const inviteRootCode = launchParams.startParam.split('--')[1]
          storage.setItem('inviteCode', inviteCode)
          if (inviteRootCode) {
            storage.setItem('inviteRootCode', inviteRootCode)
          }
        }
      }
    } catch (error) {}
  }, [])

  return <>{children}</>
}
