import { createLocalizedPathnamesNavigation } from 'next-intl/navigation'
import { Pathnames } from 'next-intl/navigation'

export const defaultLocale = 'en' as const
export const locales = ['en', 'zh', 'fr', 'ko', 'vi', 'ja', 'ru'] as const

export const pathnames = {
  // '/': '/',
  // '/invite': '/invite',
} satisfies Pathnames<typeof locales>

// Use the default: `always`
export const localePrefix = 'always'

export const { Link, getPathname, redirect, usePathname, useRouter } = createLocalizedPathnamesNavigation({
  locales,
  pathnames,
  localePrefix,
})
