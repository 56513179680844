'use client'
import { createContext, ReactNode, useContext } from 'react'
import { ResponsivePoint } from '@/utils/types'
import { useResponsive } from 'ahooks/es'

const RespContext = createContext<ResponsivePoint>({ xs: true, sm: true, md: true, lg: true, xl: true })

export function RespProvider(props: { children?: ReactNode; initValue: ResponsivePoint }) {
  const resp = useResponsive() as any as ResponsivePoint | undefined
  return <RespContext.Provider value={resp || props.initValue}>{props.children}</RespContext.Provider>
}

export function useResp() {
  return useContext(RespContext)
}
