import React, { FC } from 'react'

export interface DropdownProps {
  menu: { label: React.ReactNode }[]
  children?: React.ReactNode | string
  style?: React.CSSProperties
  menuBodyWrapperStyle?: React.CSSProperties
  menuBodyWrapperClassName?: string
}

const DefaultTriggerNode = () => <div className="text-[0] ml-[10px]">Hover me</div>

const Dropdown: FC<DropdownProps> = ({
  children,
  menu = [],
  style,
  menuBodyWrapperStyle,
  menuBodyWrapperClassName,
}) => {
  return (
    <div style={style} className="relative inline-block group">
      <div className="triggerWrapper">{children || <DefaultTriggerNode />}</div>
      <div
        className={`absolute left-0 top-[100%] mt-[12px] z-[2] opacity-0 w-min-[100%] origin-top scale-0 transition-all group-hover:opacity-100 group-hover:scale-100 ${menuBodyWrapperClassName}`}
        style={{ width: 'max-content', minWidth: '100%', ...(menuBodyWrapperStyle || {}) }}
      >
        <div className="mt-[5px] bg-[#2B2845] rounded-[8px] py-[6px] px-[6px]">
          {menu.map((item, index) => {
            return (
              <div className="menuItem" key={index}>
                {item.label}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Dropdown
