'use client'
import { XImage } from '@/views/common/XImage'
import { Images } from '@/utils/images'
import { useModal } from '@/context/ModalContext'
import { RankingModal } from '@/views/play/RankingModal'
import { useParams } from 'next/navigation'
import { useTranslations } from 'next-intl'
import { useEffect } from 'react'

export function RankingEntry() {
  const { name } = useParams<{ name: string }>()
  const { openModal } = useModal()
  const t = useTranslations('play')
  useEffect(() => {
    if (name === 'FlappyCrypto') {
      document.body.style.setProperty('--ranking-color', 'rgba(27, 223, 235, 1)')
      document.body.style.setProperty('--ranking-color-2', 'rgba(27, 223, 235, 0)')
    } else if (name === 'MEME-Merge2048') {
      document.body.style.setProperty('--ranking-color', 'rgba(236, 213, 146, 1)')
      document.body.style.setProperty('--ranking-color-2', 'rgba(236, 213, 146, 0)')
    } else if (name === 'MEME-MineSweeper') {
      document.body.style.setProperty('--ranking-color', 'rgba(203, 203, 203, 1)')
      document.body.style.setProperty('--ranking-color-2', 'rgba(203, 203, 203, 0)')
    } else if (name === 'DogeJumpChallenge') {
      document.body.style.setProperty('--ranking-color', 'rgba(159, 231, 87, 1)')
      document.body.style.setProperty('--ranking-color-2', 'rgba(159, 231, 87, 0)')
    }
  }, [name])
  return (
    <div
      className={
        'md:w-49 w-[105px] md:h-10 h-6 rounded-l-full bg-yellowFEDE08 cursor-pointer flex  lg:justify-between items-center md:pl-3 pl-2 md:pr-4.5 md:fixed md:right-0 md:top-36 max-md:-mr-2'
      }
      onClick={() => {
        openModal(RankingModal, { game: name })
      }}
    >
      <XImage src={Images.GAME.LEADER_LEFT_SVG} className={'md:h-4 h-2.5'} />
      <span className={'text-black070921 font-jua md:text-base text-[12px] md:ml-3 ml-2'}>{t('leaderboard')}</span>
      <XImage src={Images.GAME.LEADER_MEDAL_PNG} className={'h-14 hidden md:block md:ml-[12px]'} />
    </div>
  )
}
