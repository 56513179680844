'use client'

import { useRequest } from 'ahooks'
import { isTMA, postEvent } from '@telegram-apps/sdk'
import { useEffect } from 'react'

export function TmaExpand() {
  const { data: isInTelApp } = useRequest(isTMA)
  useEffect(() => {
    if (isInTelApp) {
      postEvent('web_app_expand', {})
      postEvent('web_app_setup_closing_behavior', { need_confirmation: true })
      postEvent('web_app_setup_swipe_behavior', { allow_vertical_swipe: false })
    }
  }, [isInTelApp])
  return null
}
